import React from 'react';
import { graphql, Link } from 'gatsby';
import { MapToComponents } from 'react-map-to-components';
import styled from 'styled-components';
// import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers';
// import Pagination from '../components/Pagination';
import SanityImage from 'gatsby-plugin-sanity-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import SEO from '../components/SEO';
import { mq } from '../styles/breakpoints';
import Pagination from '../components/Pagination';
import ThreeColumns from '../components/sections/ThreeColumns';
import TwoColumns from '../components/sections/TwoColumns';
import SingleColumn from '../components/sections/SingleColumn';
import Testimony from '../components/sections/Testimony';
import Hero from '../components/sections/Hero';
import BlockQuestions from '../components/sections/BlockQuestions';
import More from '../components/sections/More';
import Table2Columns from '../components/sections/Table2Columns';
import Table3Columns from '../components/sections/Table3Columns';
import TwoColumnsQuery from '../fragments/TwoColumns';
import ThreeColumnsQuery from '../fragments/ThreeColumns';
import SingleColumnQuery from '../fragments/SingleColumn';
import HeroQuery from '../fragments/Hero';
import BlockQuestionsQuery from '../fragments/BlockQuestions';
import MoreQuery from '../fragments/More';
import Table2ColumnsQuery from '../fragments/Table2Columns';
import Table3ColumnsQuery from '../fragments/Table3Columns';

const RealisationsListStyles = styled.div`
  padding-top: var(--margin);
  position: relative;

  .section-title {
    margin-bottom: var(--padding);
    text-align: center;
  }

  .realisations-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

const RealisationStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: calc(var(--padding) * 1.5);
  text-align: center;
  width: 100%;

  .image-wrapper {
    background-color: ${(props) => props.thumbBg};
    border-radius: 100%;
    margin: 0 auto 1rem;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
  }
  .image {
    border-radius: 100%;
    display: block;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateZ(0);
    transition: opacity 20000ms linear;
    will-change: opacity;
  }

  ${mq[0]} {
    margin-left: 8%;
    width: 46%;

    &:nth-child(2n + 1) {
      margin-left: 0;
    }
  }

  ${mq[2]} {
    margin-left: 2%;
    width: 32%;

    &:nth-child(2n + 1) {
      margin-left: 2%;
    }
    &:nth-child(3n + 1) {
      margin-left: 0;
    }
  }

  .title-middle {
    padding: 0 1rem;
    text-align: center;

    + p {
      margin-top: 0.5rem;
      padding: 0 1rem;
    }
  }

  .realisation-link {
    align-self: center;
    color: var(--blue-dark);
    margin-top: auto;

    &:hover {
      color: var(--white);
    }
  }
`;

export default function Realisations({ data, location, pageContext }) {
  const realisations = data.realisations.nodes;

  const { title, titleSeo, content, descriptionSeo, image } =
    data?.RealisationsPage;

  const Realisation = ({ realisation, thumbBg }) => {
    const { slug, thumbnail, title: titleReal, image1, place } = realisation;
    const src = thumbnail || image1;

    return (
      <RealisationStyles className="realisation" thumbBg={thumbBg}>
        <Link to={`/realisations/${slug.current}`}>
          <div className="image-wrapper">
            {src && (
              <SanityImage
                // pass asset, hotspot, and crop fields
                {...src}
                // tell Sanity how large to make the image (does not set any CSS)
                height={300}
                width={300}
                alt={src.asset.altText || titleReal}
                // style it how you want it
                style={{
                  width: 'auto',
                  height: '100%',
                  objectFit: 'contain',
                  opacity: '1',
                }}
                className="image"
              />
            )}
          </div>
        </Link>
        <h2 className="title-middle">
          <Link to={`/realisations/${slug.current}`}>{titleReal}</Link>
        </h2>
        {place && <p>{place}</p>}
        <Link
          to={`/realisations/${slug.current}`}
          className="button realisation-link"
        >
          Découvrir
        </Link>
      </RealisationStyles>
    );
  };

  return (
    <>
      <SEO
        title={titleSeo || title}
        description={descriptionSeo}
        location={location}
      />
      <RealisationsListStyles>
        {realisations && (
          <div className="container">
            {image?.asset && (
              <GatsbyImage
                image={getImage(image.asset)}
                alt={image.altText || title}
                className="image-header"
              />
            )}
            <h1 className="section-title">{title}</h1>
            <MapToComponents
              getKey={(section) => section.id || section._key}
              getType={(section) => section._type}
              list={content}
              map={{
                threeColumns: ThreeColumns,
                twoColumns: TwoColumns,
                singleColumn: SingleColumn,
                hero: Hero,
                testimonySection: Testimony,
                blockQuestions: BlockQuestions,
                more: More,
                table2columns: Table2Columns,
                table3columns: Table3Columns,
              }}
              mapDataToProps={{
                threeColumns: ({ data }) => ({
                  title: data.title,
                  bgTitle: data.bgTitle?.value,
                  bgSection: data.bgSection?.value,
                  showTitle: data.showTitle,
                  cards: data.cards,
                  titleOptions: data.titleOptions,
                }),
                twoColumns: ({ data }) => ({
                  alignContent: data.alignContent,
                  title: data.title,
                  showTitle: data.showTitle,
                  bgColor: data.bgColor?.value,
                  cards: data.cards,
                  colsProportions: data.colsProportions,
                  ctaButton: data.ctaButton,
                  titleOptions: data.titleOptions,
                }),
                singleColumn: ({ data }) => ({
                  background: data.background?.value,
                  alignCenter: data.alignCenter,
                  ctaButton: data.ctaButton,
                  description: data.columnDescription?._rawText,
                  title: data.title,
                  showTitle: data.showTitle,
                  titleOptions: data.titleOptions,
                }),
                hero: ({ data }) => ({
                  pageTitle: title,
                  images: data.images,
                }),
                testimonySection: ({ data }) => ({
                  testimonies: data.testimonyRef,
                  cta: data.cta,
                  ctaBg: data.ctaBg?.value,
                  ctaBgHover: data.ctaBghover?.value,
                  ctaColor: data.ctaColor?.value,
                  ctaColorHover: data.ctaColorHover?.value,
                }),
                blockQuestions: ({ data }) => ({
                  background: data.background?.value,
                  questions: data.questionsList,
                  showTitle: data.showTitle,
                  title: data.title,
                  titleOptions: data.titleOptions,
                }),
                more: ({ data }) => ({
                  text: data.moreText,
                  ctaButton: data.ctaButton,
                }),
                table2columns: ({ data }) => ({
                  rows: data.rows,
                }),
                table3columns: ({ data }) => ({
                  rows: data.rows,
                }),
              }}
            />

            <div className="realisations-list">
              {realisations.map((realisation) => (
                <Realisation
                  realisation={realisation}
                  key={`key-${realisation.id}`}
                  thumbBg={
                    realisation.image1?.asset?.metadata?.palette
                      ?._rawLightVibrant?.background
                  }
                />
              ))}
            </div>
            {data.realisations.totalCount > 12 && (
              <Pagination
                pageSize={parseInt(process.env.GATSBY_PAGE_SIZE)}
                totalCount={data.realisations.totalCount}
                currentPage={pageContext.currentPage || 1}
                base="/realisations"
              />
            )}
          </div>
        )}
      </RealisationsListStyles>
    </>
  );
}

export const query = graphql`
  query RealisationsQuery($skip: Int = 0, $pageSize: Int = 12) {
    RealisationsPage: sanityPage(slug: { current: { eq: "realisations" } }) {
      title
      titleSeo
      descriptionSeo
      image {
        asset {
          altText
          gatsbyImageData(width: 900)
        }
      }
      content {
        ...SingleColumnQuery
        ...TwoColumnsQuery
        ...ThreeColumnsQuery
        ...HeroQuery
        ...MoreQuery
        ...BlockQuestionsQuery
        ...TableauQuery
        ...Table2ColumnsQuery
        ...Table3ColumnsQuery
      }
    }
    realisations: allSanityRealisation(
      limit: $pageSize
      skip: $skip
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } } }
    ) {
      totalCount
      nodes {
        id
        title
        type {
          slug {
            current
          }
          type
        }
        thumbnail {
          ...Image
          asset {
            altText
            metadata {
              palette {
                _rawLightVibrant(resolveReferences: { maxDepth: 10 })
              }
              dimensions {
                aspectRatio
              }
            }
          }
        }
        place
        image1 {
          ...Image
          asset {
            altText
            metadata {
              palette {
                _rawLightVibrant(resolveReferences: { maxDepth: 10 })
              }
            }
          }
        }
        slug {
          current
        }
      }
    }
  }
`;
